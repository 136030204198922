<template>
  <div class="divContainer">
    <div
      v-if="iQuoteInitialise_isLoaded && viewiQuotePermission"
      class="appSectionContainer">
      <TopMenu
        :map="menuMap"
        :selected-top-menu-item="selectedTopMenu"
        :selected-sub-menu-item="selectedSubMenu"
        @showPage="handleShowPageEvent" />
      <div class="iQuoteMainPanel">
        <component :is="currentForm"/>
      </div>
    </div>
    <Loader
      :is-loading="!iQuoteInitialised && viewiQuotePermission"
      message="Launching iQuote"
    />
  </div>
</template>

<script>
import Loader from '../components/common/loader'
import Home from '../components/iQuote/menuScreens/home'
import CreateQuote from '../components/iQuote/menuScreens/createQuote'
import CommercialAudit from '../components/iQuote/menuScreens/commercialAudit'
import CommercialDesignPack from '../components/iQuote/menuScreens/commercialDesignPack'
import CommercialSettings from '../components/iQuote/menuScreens/commercialSettings'
import AdminVirtualMachines from '../components/iQuote/menuScreens/adminVirtualMachines'
import AdminLicenses from '../components/iQuote/menuScreens/adminLicenses'
import AdminUnitCosts from '../components/iQuote/menuScreens/adminUnitCosts'
import AdminHome from '../components/iQuote/menuScreens/adminHome'
import AdminReports from '../components/iQuote/menuScreens/adminReports'
import TopMenu from '../components/common/topMenu'
import { iQuoteInitialiseMixin } from '../mixins/iQuote/iQuoteInitialiseMixin'
import { iQuoteStoreMixin } from '../mixins/iQuote/iQuoteStoreMixin'
import Help from './Help'
import Support from './Support'
import Security from './Security'
import UserManagement from './UserManagement'
import UserProfile from './UserProfile'

import { DataEventBus } from '../events/dataEvents'

import { mapState, mapActions } from 'vuex'

export default {
  metaInfo: {
    meta: [
      { name: 'description', content: 'Maintel iQuote Prototype' }
    ],
    title: 'Icon Portal - iQuote Home Page'
  },
  components: {
    Home,
    CreateQuote,
    AdminVirtualMachines,
    AdminLicenses,
    AdminUnitCosts,
    AdminHome,
    AdminReports,
    CommercialAudit,
    CommercialDesignPack,
    CommercialSettings,
    Loader,
    TopMenu,
    Help,
    Support,
    Security,
    UserManagement,
    UserProfile
  },
  mixins: [iQuoteInitialiseMixin, iQuoteStoreMixin],
  data () {
    return {
      currentForm: '',
      selectedTopMenu: 'home',
      selectedSubMenu: '',
      iQuoteInitialised: false
    }
  },
  computed: {
    ...mapState([
      'currentQuotePage'
    ]),
    menuMap () {
      var map = {
        iQuote: { page: 'Home', isIquoteMenuItem: true },
        createQuote: { page: 'CreateQuote' }
      }

      map.commercial = {
        designPack: { page: 'CommercialDesignPack' }
      }

      if (this.$store.getters.iQuoteTesterPermission) {
        map.commercial.audit = { page: 'CommercialAudit' }
        map.commercial.settings = { page: 'CommercialSettings' }

        map.admin = {
          home: { page: 'AdminHome' },
          reports: { page: 'AdminReports' },
          virtualMachines: { page: 'AdminVirtualMachines' },
          licenses: { page: 'AdminLicenses' },
          unitCosts: { page: 'AdminUnitCosts' }
        }
      } else {
        map.admin = {
          home: { page: 'AdminHome' },
          reports: { page: 'AdminReports' }
        }
      }

      map.Help = { page: 'Help' }
      map.Support = { page: 'Support' }
      map.Security = { page: 'Security' }
      map.userManagement = { page: 'UserManagement' }
      map.myAccount = { page: 'UserProfile' }
      return map
    },
    viewiQuotePermission () {
      return this.$store.getters.viewiQuotePermission && (this.isMaintelUser || this.$store.getters.iQuoteTesterPermission)
    },
    currentPage () {
      return this.$store.getters.currentPage
    },
    isMaintelUser () {
      if (this.$store.getters.username === 'kevin.painton') {
        return true
      }

      var isChannel = false
      this.$store.getters.companies.forEach(company => {
        if (company.id === this.$store.getters.channelCompany.companyId) {
          isChannel = true
        }
      })
      return isChannel
      // return this.$store.getters.userEmail.toLowerCase().endsWith('@maintel.co.uk') && this.$store.getters.userEmail.toLowerCase() !== 'portalquery@maintel.co.uk'
    }
  },
  async mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.iQuoteInitialise()
    })
  },
  async updated () {
    if (this.$router.currentRoute.path.startsWith('/iquote/designpacks/') &&
        this.currentForm === '' &&
      (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined ||
       this.$route.params.designpackrevisionid !== this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId)) {
      this.currentForm = 'commercialDesignPack'
      this.selectedTopMenu = 'commercial'
      this.selectedSubMenu = 'designPack'
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuotePage',
      'setCurrentQuoteState',
      'setQuote',
      'setDesignPack'
    ]),
    async iQuoteInitialise () {
      if (this.iQuoteInitialised) {
        return
      }

      if (this.currentForm === '' && !this.$router.currentRoute.path.startsWith('/iquote/designpacks/')) {
        this.currentForm = 'home'
      }

      if (this.viewiQuotePermission) {
        await this.iQuoteInitialise_loadStaticData()
        this.iQuoteIsReady()
        this.iQuoteInitialised = true
      }

    },
    iQuoteIsReady: function () {
      console.log('Adding watch for changing current form')
      this.$store.watch(
        (state, getters) => getters.currentQuotePage, (newValue) => {
          console.log('Quote page current form triggered: ' + newValue)
          this.currentForm = newValue
          DataEventBus.$emit('still-active')
          this.setSelectedMenuFromPage(newValue)
        })

      this.setCurrentQuotePage('Home')
      this.setCurrentQuoteState('QuoteType')
    },
    /*
      Based on the page that iQuote is displaying, work out what the currently selected top menu, and top submenu are
    */
    setSelectedMenuFromPage (newPage) {
      var item = null

      var keys = Object.keys(this.menuMap)

      //  Loop through the top menu items
      for (let index = 0; index < keys.length; index++) {
        const topMenuName = keys[index]
        if (this.menuMap[topMenuName].page !== undefined) {
          // A page has been defined for this menu entry, so check to see if the page is the one that we've just changed to
          if (this.menuMap[topMenuName].page === newPage) {
            this.selectedTopMenu = topMenuName
            this.selectedSubMenu = ''
            return
          } else if (topMenuName.toLowerCase() === newPage.toLowerCase()) {

          }
        } else {
          var subItemKeys = Object.keys(this.menuMap[topMenuName])
          for (let index = 0; index < subItemKeys.length; index++) {
            const subMenuName = subItemKeys[index]
            if (this.menuMap[topMenuName][subMenuName].page !== undefined) {
              if (this.menuMap[topMenuName][subMenuName].page === newPage) {
                this.selectedTopMenu = topMenuName
                this.selectedSubMenu = subMenuName
                return
              }
            }
          }
        }
      }

      // The URL field, when in commercial tab will be updating according to the DP that you are currently in.
      // We want to remove that if you navigate away
      if (this.selectedTopMenu !== 'commercial') {
        history.pushState(
          {},
          null,
          '/iquote'
        )
      }

      return item
    },
    handleShowPageEvent: function (newPage) {
      if (this.$store.getters.currentQuotePage === newPage) {
        // Clicked on page that we are already on, so ignore
        return
      }

      switch (newPage.toLowerCase()) {
        case 'home':
          break
        case 'createquote':
          this.setQuote({})
          this.setCurrentQuoteState('QuoteType')
          break
        case 'commercialdesignpack':
          // this.setDesignPack({})
          break
      }

      this.setCurrentQuotePage(newPage) /* Note that this will be noticed by the watcher in our "Mounted" handler above and cause the menu to change */
    }
  }
}
</script>

<style scoped>
.loadingMessage {
  text-align: center
}

.divContainer {  
  width: 104%;
}
</style>
